import "./App.css";

function App() {
	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
			}}
		>
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					backgroundColor: "white",
					width: "100%",
				}}
			>
				<div
					style={{
						display: "flex",
						//flexDirection: "raw",
						flexWrap: "wrap",
						alignItems: "center",
					}}
				>
					<img src="ipad.png" />
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							alignItems: "start",
						}}
					>
						<div
							style={{
								fontSize: "60px",
								fontWeight: "bold",
								paddingLeft: "20px",
								color: "black",
								textAlign: "center",
							}}
						>
							KanjiPad
						</div>
						<div
							style={{
								fontSize: "35px",
								paddingLeft: "20px",
								color: "black",
								textAlign: "center",
							}}
						>
							learn japanese kanji by writing
						</div>
						<div
							style={{
								fontSize: "40px",
								paddingLeft: "20px",
								paddingTop: "20px",
								color: "black",
								textAlign: "start",
							}}
						>
							<img src="appStore.png" width={150} />
						</div>
					</div>
				</div>
			</div>
			<div
				style={{
					marginTop: "10px",
					color: "white",
				}}
			>
				KanjiPad © 2022
			</div>
		</div>
	);
}

export default App;
